import React from "react";

import {
  createUseStyles,
  ROUTES,
  Routes,
  Route,
  // @ts-ignore
} from "@projectdiction/common-react";
import Main from "./Main";
import ViewerMain from "./Viewer/ViewerMain";
import CourseViewerPage from "./Viewer/Course/CourseViewerPage";
import CreatorMain from "./Creator/CreatorMain";
import CourseCreatorPage from "./Creator/Course/CourseCreator/CourseCreatorPage";
import EditorMain from "./Creator/EditorMain";
import Navigator from "./Navigator/Navigator";

export default function App() {
  return (
    <div className="h-full w-full overflow-hidden">
      <Routes>
        <Route exact path={ROUTES.COURSES.HOME} element={<Main />} />
        <Route
          exact
          path={ROUTES.COURSES.CREATOR.HOME}
          element={<CreatorMain />}
        />
        <Route
          // path={ROUTES.COURSES.CREATOR.COURSE_ID}
          path="/courses/creator/*"
          element={<CourseCreatorPage />}
        />
        <Route path={ROUTES.COURSES.CREATOR.EDIT_ID} element={<EditorMain />} />
        <Route
          exact
          path={ROUTES.COURSES.VIEWER.HOME}
          element={<ViewerMain />}
        />
        <Route
          exact
          // path={ROUTES.COURSES.VIEWER.COURSE_ID}
          path="/courses/viewer/*"
          element={<CourseViewerPage />}
        />
        <Route path="/courses/navigate/*" element={<Navigator />} />
      </Routes>
    </div>
  );
}
