import React from "react";

import {
  Button,
  Card,
  Editor,
  InplaceEditable,
  PanelMenu,
  Menubar,
  useAccessToken,
  useUserPreferences,
  useNavigate,
  useLocation,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourseMenu,
  // @ts-ignore
} from "@projectdiction/api-helper";
import DuolingoStyleBubble from "./DuolingoStyleBubble";

export default function DuolingoStyleViewer() {
  let { pathname } = useLocation();

  const containsScreen = pathname.includes("screen");

  const courseId = containsScreen
    ? pathname.split("/").slice(-3)[0]
    : pathname.split("/").pop();

  const [menuItems, setMenuItems] = React.useState<any>();
  const [expandedKeys, setExpandedKeys] = React.useState<any>({});

  const token = useAccessToken();

  const [courseMenu, setCourseMenu] = React.useState<any>();

  const navigate = useNavigate();

  React.useEffect(() => {
    let courseIds;
    if (containsScreen) {
      const screenId = pathname.split("/").pop();
      courseIds = pathname
        .replace(`/courses/viewer`, "")
        .replace(`/screen/${screenId}`, "")
        .split("/");
    } else {
      courseIds = pathname.replace(`/courses/viewer`, "").split("/");
    }

    courseIds.forEach((key) => {
      expandedKeys[key] = true;
    });

    setExpandedKeys(expandedKeys);
  }, [pathname]);

  React.useEffect(() => {
    if (!token) return;

    getCourseMenu(token, courseId)
      .then((response) => {
        setCourseMenu(response.data.course);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token, courseId]);

  React.useEffect(() => {
    if (!courseMenu) return;

    function createMenuItemsRecursively(data, path, depth) {
      return data.map((chapter, i) => {
        const hasSections = chapter.sections.length > 0;
        const hasScreens = chapter.screens.length > 0;

        return {
          key: chapter._id,
          label: chapter.title,
          items: hasSections
            ? createMenuItemsRecursively(
                chapter.sections,
                `${path}/${chapter._id}`,
                depth + 1
              )
            : chapter.screens.map((screen) => {
                return {
                  key: screen._id,
                  label: screen.title,
                  data: {
                    path: `${path}/${chapter._id}/screen/${screen._id}`,
                    depth: depth + 1,
                    screenId: screen._id,
                  },
                  template: (item, options) => {
                    return (
                      <DuolingoStyleBubble
                        key={screen._id}
                        item={item}
                        options={options}
                        type="screen"
                        currentScreenId={courseMenu.currentScreen}
                      />
                    );
                  },
                };
              }),
          data: {
            path: `${path}/${chapter._id}`,
            hasScreens,
            hasSections,
            depth,
          },
          expanded: true,
          template: (item, options) => {
            return (
              <DuolingoStyleBubble
                key={chapter._id}
                item={item}
                options={options}
                type="course"
                currentScreenId={courseMenu.currentScreen}
              />
            );
          },
        };
      });
    }

    function filterSingleMenuItem(item) {
      const { hasScreens, hasSections } = item.data;

      if (!hasScreens && !hasSections) {
        return false;
      }

      if (hasScreens) {
        return true;
      }

      let shouldDisplay = false;

      item.items.forEach((subItem) => {
        if (subItem.data.hasSections || subItem.data.hasScreens) {
          shouldDisplay = true;
        }
      });

      return shouldDisplay;
    }

    function filterMenuItemsRecursively(menuItems) {
      return menuItems.filter(filterSingleMenuItem); // may need adjusting
    }

    setMenuItems(
      filterMenuItemsRecursively(
        createMenuItemsRecursively(
          courseMenu.sections,
          `/courses/viewer/${courseMenu._id}`,
          0
        )
      )
    );
  }, [courseMenu]);

  const commonClasses = "flex flex-column align-items-center";

  return (
    <PanelMenu
      pt={{
        root: { className: commonClasses + " " },
        panel: { className: commonClasses + " " },
        toggleableContent: { className: "" },
        menuContent: { className: "" },
        menu: { className: commonClasses + " " },
        menuitem: { className: commonClasses + " " },
        submenu: { className: commonClasses + " " },
        action: { className: "" },
        headerAction: { className: "" },
        label: { className: "" },
      }}
      multiple
      submenuIcon="pi"
      unstyled
      model={menuItems || []}
    />
  );
}
