import React from "react";
import {
  NavigationGrid,
  ROUTES,
  useAccessToken,
  useUserPreferences,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  getAllCourses,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function EditorMain() {
  const { t } = useTranslation();

  const token = useAccessToken();

  const userPreferences = useUserPreferences();

  const [courses, setCourses] = React.useState([]);

  React.useEffect(() => {
    if (!token) return;
    if (!userPreferences) return;

    const fetchCourses = async () => {
      const response = await getAllCourses(
        token,
        userPreferences.languages.studyLanguage,
        userPreferences.languages.knownLanguage
      );
      setCourses(response.data.courses);
    };

    fetchCourses();
  }, [token, userPreferences]);

  return (
    <NavigationGrid
      title={t("courses.creator.titles.edit_course")}
      data={courses.map((course) => {
        return {
          title: course.title,
          route: ROUTES.COURSES.CREATOR.COURSE(course._id),
          description: course.description,
          label: t("courses.buttons.edit"),
        };
      })}
    />
  );
}
