import React from "react";
import {
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function CreatorMain() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      data={[
        {
          title: t("courses.creator.titles.create_course"),
          route: ROUTES.COURSES.CREATOR.COURSE("newCourse"),
          description: t("courses.creator.description.create_course"),
          label: t("buttons.navigation.go"),
        },
        {
          title: t("courses.creator.titles.edit_course"),
          route: ROUTES.COURSES.CREATOR.EDIT("editCourse"),
          description: t("courses.creator.description.edit_course"),
          label: t("buttons.navigation.go"),
        },
      ]}
    />
  );
}
