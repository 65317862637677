import React from "react";

import {
  createUseStyles,
  ReadingItemHiglightPOSParagraph,
  ReadingItemParallelParagraph,
  // @ts-ignore
} from "@projectdiction/common-react";
import { SCREEN_TYPES } from "../../../../../SCREEN_TYPES";

export default function ReadingItemSection({
  headingLevel,
  heading,
  content,
  studyLanguage,
  knownLanguage,
  type,
}) {
  const generateHeading = (headingLevel: number, heading: any) => {
    switch (headingLevel) {
      case 0:
        return null;
      case 2:
        return <h2>{heading}</h2>;
      case 3:
        return <h3>{heading}</h3>;
      case 4:
        return <h4>{heading}</h4>;
      case 5:
        return <h5>{heading}</h5>;
      case 6:
        return <h6>{heading}</h6>;
      default:
        return <h1>{heading}</h1>;
    }
  };

  return (
    <div className="flex flex-column align-items-center">
      {generateHeading(
        headingLevel,
        type === SCREEN_TYPES.READING_TRANSLATION ? (
          <ReadingItemParallelParagraph
            text={heading}
            studyLanguage={studyLanguage}
            knownLanguage={knownLanguage}
          />
        ) : type === SCREEN_TYPES.READING_POS ? (
          // @ts-ignore
          <ReadingItemHiglightPOSParagraph text={heading} />
        ) : (
          heading
        )
      )}
      {content.map((paragraph: string, index: any) => {
        return type === SCREEN_TYPES.READING_TRANSLATION ? (
          <ReadingItemParallelParagraph
            key={index}
            text={paragraph}
            studyLanguage={studyLanguage}
            knownLanguage={knownLanguage}
          />
        ) : type === SCREEN_TYPES.READING_POS ? (
          // @ts-ignore
          <ReadingItemHiglightPOSParagraph key={index} text={paragraph} />
        ) : (
          <p key={index}>{paragraph}</p>
        );
      })}
    </div>
  );
}
