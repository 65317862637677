import React from "react";

import {
  Card,
  useLocation,

  // @ts-ignore
} from "@projectdiction/common-react";

function ScreenViewer({ screenId }) {
  return <Card title="Screen Viewer" subTitle={screenId} />;
}

function CourseViewer({ courseId }) {
  return <Card title="Course Viewer" subTitle={courseId} />;
}

export default function Navigator() {
  const { pathname } = useLocation();
  return <Card title="Navigator"></Card>;
}
