import React from "react";

import {
  Card,
  Dropdown,
  useNavigate,
  useAccessToken,
  useLocation,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addScreen,
  // @ts-ignore
} from "@projectdiction/api-helper";

import { SCREEN_TYPES } from "../../../SCREEN_TYPES";
import ScreenEditor from "./ScreenEditor";

export default function ScreenCreator({ parentViewUpdater, updateParentView }) {
  const accessToken = useAccessToken();
  const navigate = useNavigate();
  const [screenType, setScreenType] = React.useState();

  const { pathname } = useLocation();
  const screenId = pathname.split("/").pop();
  const courseId = pathname.replace(`/screen/${screenId}`, "").split("/").pop();

  const isNewScreen = screenId === "newScreen";

  return (
    <Card
      footer={""}
      pt={{
        root: { className: "h-full w-full" },
        body: { className: "h-full w-full p-0" },
        title: { className: "w-full flex justify-content-center" },
        subTitle: { className: "w-full flex justify-content-center" },
        content: {
          className: "w-full flex justify-content-center p-0",
          style: {
            height: isNewScreen ? "calc(100% - 11rem)" : "calc(100% - 3rem)",
          },
        },
        footer: { className: "w-full flex justify-content-center" },
      }}
    >
      <div className="h-full w-full grid">
        <div className="col-12 h-full">
          {isNewScreen ? (
            <div className="flex justify-content-center w-full h-3rem">
              <Dropdown
                value={screenType}
                options={[
                  { label: "Reading", value: SCREEN_TYPES.READING },
                  {
                    label: "Reading Translation",
                    value: SCREEN_TYPES.READING_TRANSLATION,
                  },
                  {
                    label: "Reading Vocabulary",
                    value: SCREEN_TYPES.READING_VOCABULARY,
                  },
                  { label: "Reading POS", value: SCREEN_TYPES.READING_POS },
                  {
                    label: "Reading Highlighted Words",
                    value: SCREEN_TYPES.READING_HIGHLIGHTED_WORDS,
                  },
                  {
                    label: "Vocabulary List",
                    value: SCREEN_TYPES.VOCABULARY_LIST,
                  },
                  {
                    label: "Vocabulary Deck",
                    value: SCREEN_TYPES.VOCABULARY_DECK,
                  },
                  { label: "Fill Blank", value: SCREEN_TYPES.FILL_BLANK },
                ]}
                onChange={(e) => {
                  if (!e.value) return;
                  setScreenType(e.value);
                  addScreen(accessToken, courseId, e.value).then((res) => {
                    navigate(
                      pathname.replace("/newScreen", `/${res.data._id}`)
                    );
                  });
                }}
                showClear
              />
            </div>
          ) : (
            <ScreenEditor
              parentViewUpdater={parentViewUpdater}
              updateParentView={updateParentView}
              courseId={courseId}
            />
          )}
        </div>
      </div>
    </Card>
  );
}
