export const SCREEN_TYPES = {
  READING: "ReadingScreen",
  READING_TRANSLATION: "ReadingTranslationScreen",
  READING_VOCABULARY: "ReadingVocabularyScreen",
  READING_POS: "ReadingPOSScreen",
  READING_HIGHLIGHTED_WORDS: "ReadingHighlightedWordsScreen",
  VOCABULARY: "VocabularyScreen",
  VOCABULARY_LIST: "VocabularyListScreen",
  VOCABULARY_DECK: "VocabularyDeckScreen",
  FILL_BLANK: "FillBlankScreen",
};
