import React from "react";

import {
  InplaceEditable,
  Button,
  useAccessToken,
  useNavigate,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addSection,
  deleteCourse,
  updateCourse,
  updateScreen,
  moveCourse,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function TOCMenuItem({ item, updateParentView, type, path }) {
  const [showButtons, setShowButtons] = React.useState(false);

  React.useEffect(() => {
    // check if touch screen
    if ("ontouchstart" in window) {
      setShowButtons(true);
    }
  }, []);

  const accessToken = useAccessToken();

  const navigate = useNavigate();

  return (
    <div
      className="p-3 cursor-pointer flex"
      style={{ cursor: "default" }}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <InplaceEditable
          text={item.title}
          updateText={(title) => {
            if (type === "screen") {
              updateScreen(accessToken, item._id, item.__t, {
                title,
              }).then(() => {
                updateParentView();
              });
              return;
            }
            updateCourse(accessToken, item._id, {
              title,
            }).then(() => {
              updateParentView();
            });
          }}
          classes={{
            root: "flex h-2rem",
            content: type === "screen" ? "underline" : "",
          }}
        />
      </div>
      {showButtons && type === "course" && (
        <div
          className="ml-auto "
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            key="moveUp"
            icon="pi pi-arrow-up"
            size="small"
            className="h-2rem w-2rem"
            text
            plain
            onClick={() => {
              moveCourse(accessToken, item._id, "up")
                .then(() => {
                  updateParentView();
                })
                .catch(() => {});
            }}
          />
          <Button
            key="moveDown"
            icon="pi pi-arrow-down"
            size="small"
            className="h-2rem w-2rem"
            text
            plain
            onClick={() => {
              moveCourse(accessToken, item._id, "down")
                .then(() => {
                  updateParentView();
                })
                .catch(() => {});
            }}
          />
          {(!item.screens || item.screens.length === 0) && (
            <Button
              key="add section"
              icon="pi pi-plus"
              size="small"
              className="h-2rem w-2rem"
              text
              plain
              onClick={() => {
                addSection(accessToken, item._id).then(() => {
                  updateParentView();
                });
              }}
            />
          )}
          {(!item.sections || item.sections.length === 0) && (
            <Button
              key="add screen"
              icon="pi pi-tablet"
              size="small"
              className="h-2rem w-2rem"
              text
              plain
              onClick={() => {
                navigate(`${path}/screen/newScreen`);
              }}
            />
          )}
          {(!item.screens || item.screens.length === 0) &&
            (!item.sections || item.sections.length === 0) && (
              <Button
                key="delete"
                icon="pi pi-trash"
                size="small"
                className="h-2rem w-2rem"
                text
                plain
                onClick={() => {
                  deleteCourse(accessToken, item._id).then(() => {
                    updateParentView();
                  });
                }}
              />
            )}
        </div>
      )}
    </div>
  );
}
