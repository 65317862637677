import React from "react";

import {
  InplaceEditable,
  useAccessToken,
  useNavigate,
  useLocation,
  BreadCrumb,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourseMenu,
  getScreen,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function TOCMenuBreadCrumb({ parentViewUpdater }) {
  const { pathname } = useLocation();

  const containsScreen = pathname.includes("screen");

  const courseId = containsScreen
    ? pathname.split("/").slice(-3)[0]
    : pathname.split("/").pop();

  const token = useAccessToken();

  const [courseMenu, setCourseMenu] = React.useState<any>();
  const [menuItems, setMenuItems] = React.useState<any>();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) return;

    getCourseMenu(token, courseId)
      .then((response) => {
        setCourseMenu(response.data.course);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token, courseId, parentViewUpdater, pathname]);

  React.useEffect(() => {
    if (!courseMenu) return;

    let courseIds = pathname.replace("/courses/creator/", "").split("/");
    let screenId;

    if (containsScreen) {
      courseIds = courseIds.slice(0, courseIds.length - 2);
      screenId = pathname.split("/").pop();
    }

    if (courseIds.length === 1) {
      return;
    }

    let course = courseMenu;
    let path = `/courses/creator/${course._id}`;

    let menuItems: any = [];

    for (let i = 1; i < courseIds.length; i++) {
      course = course.sections.find((section) => section._id === courseIds[i]);

      path += `/${course._id}`;

      menuItems.push({
        key: course._id,
        label: course.title,
        data: path,
        command: (e: any) => navigate(e.item.data),
      });
    }

    if (screenId) {
      if (screenId === "newScreen") {
        menuItems.push({
          key: screenId,
          label: "New Screen",
          template: <div className="underline cursor-pointer">New Screen</div>,
        });
        setMenuItems(menuItems);
        return;
      }

      const screen = course.screens.find((screen) => screen._id === screenId);

      if (screen) {
        path += `/screen/${screenId}`;

        const screenMenuItem = {
          key: screenId,
          data: path,
          command: (e) => navigate(e.item.data),
          template: (
            <div className="underline cursor-pointer"> {screen.title}</div>
          ),
        };

        menuItems.push(screenMenuItem);
      }
    }

    setMenuItems(menuItems);
  }, [courseMenu, courseId, pathname]);

  if (!courseMenu) {
    return null;
  }

  if (!menuItems) {
    return null;
  }

  return (
    <BreadCrumb
      home={{
        label: courseMenu.title,
        command: () => {
          setMenuItems(null);
          navigate(`/courses/creator/${courseMenu._id}`);
        },
      }}
      model={menuItems || []}
      separatorIcon={menuItems ? null : "pi"}
    />
  );
}
