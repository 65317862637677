import React from "react";

import {
  useNavigate,
  useLocation,
  useAccessToken,
  Card,
  Button,
  ReadingListViewItem,
  ROUTES,
  ReactRouterLink,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourse,
  getScreen,
  setCourseCurrentScreen,
  // @ts-ignore
} from "@projectdiction/api-helper";

import ReadingTranslationScreen from "./Reading/ReadingTranslationScreen/ReadingTranslationScreen";
import ReadingVocabularyScreen from "./Reading/ReadingVocabularyScreen";
import VocabularyListScreen from "./Vocabulary/VocabularyListScreen";
import VocabularyDeckScreen from "./Vocabulary/VocabularyDeckScreen";
import FillBlankScreen from "./Exercises/FillBlank/FillBlankScreen";
import ReadingHighlightedWordsScreen from "./Reading/ReadingHighlightedWordsScreen";
import { SCREEN_TYPES } from "../../../SCREEN_TYPES";

const renderScreen = (screen) => {
  switch (screen.__t) {
    case SCREEN_TYPES.READING:
      return <ReadingListViewItem {...screen} />;
    case SCREEN_TYPES.READING_TRANSLATION:
    case SCREEN_TYPES.READING_POS:
      return <ReadingTranslationScreen {...screen} type={screen.__t} />;
    case SCREEN_TYPES.READING_VOCABULARY:
      return <ReadingVocabularyScreen {...screen} />;
    case SCREEN_TYPES.READING_HIGHLIGHTED_WORDS:
      return <ReadingHighlightedWordsScreen {...screen} />;
    case SCREEN_TYPES.VOCABULARY_LIST:
      return <VocabularyListScreen {...screen} />;
    case SCREEN_TYPES.VOCABULARY_DECK:
      return <VocabularyDeckScreen {...screen} />;
    case SCREEN_TYPES.FILL_BLANK:
      return <FillBlankScreen {...screen} />;
  }
};

export default function MainScreen({ viewUpdater, updateView }) {
  const { pathname } = useLocation();
  const screenId = pathname.split("/").pop();
  const courseId = pathname.replace(`/screen/${screenId}`, "").split("/").pop();
  const mainCourseId = pathname
    .replace(`/screen/${screenId}`, "")
    .split("/")[3];

  const navigate = useNavigate();

  const [course, setCourse] = React.useState<any>();
  const [screen, setScreen] = React.useState<any>();

  const accessToken = useAccessToken();

  React.useEffect(() => {
    if (accessToken) {
      getCourse(accessToken, courseId)
        .then((response) => {
          setCourse(response.data.course);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, courseId]);

  React.useEffect(() => {
    if (accessToken) {
      getScreen(accessToken, screenId)
        .then((response) => {
          setScreen(response.data.screen);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, screenId]);

  if (!course || !screen) {
    return (
      <Card
        className="h-full w-full"
        title="Loading..."
        subTitle="Please wait..."
      />
    );
  }

  return (
    <Card
      className="card-root-with-header-footer"
      pt={{
        footer: { className: "flex justify-content-center gap-4" },
      }}
      footer={
        <>
          <Button
            disabled={!screen.previousScreen}
            className="w-8rem flex justify-content-center"
            onClick={() => {
              setCourseCurrentScreen(
                accessToken,
                mainCourseId,
                screen.previousScreen
              ).then(() => {
                updateView();
                navigate(screen.previousScreenPath);
              });
            }}
            icon="pi pi-arrow-left"
            label="Previous"
          />
          {screen.readingItemId && (
            <ReactRouterLink
              to={ROUTES.READING.READING_LIST.VIEW(screen.readingItemId)}
              target="_blank"
            >
              <Button icon="pi pi-book" label="View" />
            </ReactRouterLink>
          )}
          <Button
            disabled={!screen.nextScreen}
            className="w-8rem flex justify-content-center"
            onClick={() => {
              setCourseCurrentScreen(
                accessToken,
                mainCourseId,
                screen.nextScreen
              ).then(() => {
                updateView();
                navigate(screen.nextScreenPath);
              });
            }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Next"
          />
        </>
      }
    >
      <div
        key={screen._id}
        className="w-full h-full animate__animated animate__zoomInUp"
      >
        {renderScreen(screen)}
      </div>
    </Card>
  );
}
