import React from "react";

import {
  useAccessToken,
  useNavigate,
  useUserPreferences,
  createUseStyles,
  Button,
  Card,
  Editor,
  InputText,
  Messages,
  InputTextarea,
  Toast,
  STYLES_CARD,
  parseHtmlToContentSections,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addReadingItem, // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function ReadingItemForm({ readingItem, setReadingItem }) {
  const [title, setTitle] = React.useState("");
  const [subtitle, setSubtitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [source, setSource] = React.useState("");

  const [text, setText] = React.useState("");

  const messagesRef = React.useRef();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const token = useAccessToken();

  const toast = React.useRef();

  const userPreferences = useUserPreferences();

  const saveReadingList = async function () {
    if (title === "") {
      // @ts-ignore
      messagesRef.current.show({
        severity: "error",
        summary: t("reading.lists.toasts.summaries.save_fail"),
        detail: t("reading.lists.toasts.details.title_required"),
      });

      return;
    }
    if (text === "") {
      // @ts-ignore
      messagesRef.current.show({
        severity: "error",
        summary: t("reading.lists.toasts.summaries.save_fail"),
        detail: t("reading.lists.toasts.details.text_required"),
      });

      return;
    }

    const response = await addReadingItem(token, {
      text: parseHtmlToContentSections(text),
      title,
      subtitle,
      author,
      source,
      studyLanguage: userPreferences.languages.studyLanguage,
      type: "course",
      checkDictionaryId: "6637e2e53653d54fb241dec1",
    });

    setReadingItem(response.data.readingItem);

    // @ts-ignore
    toast.current.show({
      severity: "success",
      summary: t("reading.lists.toasts.summaries.save_success"),
      detail: t("reading.lists.toasts.details.save_success"),
    });

    // setReadingItem(response.data.readingItem);
  };

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <select className="ql-header" defaultValue="0">
          <option value="0"></option>
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
        </select>
      </span>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Card
        title={t("reading.lists.titles.add_reading_item")}
        footer={
          <div className="flex align-items-center justify-content-center w-full">
            <Button
              type="button"
              label={t("reading.lists.buttons.save")}
              onClick={saveReadingList}
            />
          </div>
        }
        pt={{
          root: { className: "h-full w-full" },
          title: { className: "flex justify-content-center w-full" },
          content: { className: "w-full", style: { overflow: "auto" } },
          body: { className: "flex flex-column h-full w-full" },
          footer: { className: "flex justify-content-end mt-auto w-full" },
        }}
      >
        <div className="field w-12 ">
          <Messages ref={messagesRef}></Messages>
          <span className="p-float-label">
            <InputText
              id="readingListTitleInput "
              className="w-full"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="readingListTitleInput" className="sr-only">
              {t("reading.lists.texts.title")}
            </label>
          </span>
        </div>

        <div className="field p-float-label w-12">
          <span className="p-float-label">
            <Editor
              value={text}
              onTextChange={(e) => setText(e.htmlValue)}
              headerTemplate={header}
              className="h-17rem"
            ></Editor>
          </span>
        </div>

        <div className="field w-12 mt-5">
          <span className="p-float-label">
            <InputText
              id="readingListSubtitleInput"
              className="w-full"
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
            <label htmlFor="readingListSubtitleInput" className="sr-only">
              {t("reading.lists.texts.subtitle")}
            </label>
          </span>
        </div>

        <div className="field w-12">
          <span className="p-float-label">
            <InputText
              id="readingListAuthorInput"
              className="w-full"
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
            <label htmlFor="readingListAuthorInput" className="sr-only">
              {t("reading.lists.texts.author")}
            </label>
          </span>
        </div>
        <div className="field w-12">
          <span className="p-float-label">
            <InputText
              id="readingListSourceInput"
              className="w-full"
              type="text"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />
            <label htmlFor="readingListSourceInput" className="sr-only">
              {t("reading.lists.texts.source")}
            </label>
          </span>
        </div>
      </Card>
      <Toast ref={toast} />
    </>
  );
}
