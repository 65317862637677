import React from "react";
import {
  getDictionary,
  addWordToDictionary,
  updateWordInDictionary,
  deleteWordFromDictionary,
  deleteDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  createUseStyles,
  confirmDialog,
  useNavigate,
  Card,
  ConfirmDialog,
  Button,
  InputText,
  Divider,
  Dialog,
  Toast,
  Skeleton,
  ROUTES,
  useAccessToken,
  useParams,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import FlipCard from "./FlipCard";

const useStyles = createUseStyles({
  root: {
    "& .ddcontainer": {
      width: "100%",
      height: "10%",
    },
    "& .ddcontainersource": {
      height: "100%",
      width: "100%",
    },
  },
});

export default function VocabularyDeckScreen({ dictionaryId }) {
  const classes = useStyles();

  const [dictionary, setDictionary] = React.useState<any>();

  const { t } = useTranslation();

  const token = useAccessToken();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    if (dictionaryId) {
      getDictionary(token, { dictionaryId })
        .then((response: any) => {
          setDictionary(response.data.dictionary);
        })
        .catch((error: any) =>
          console.log(
            "error in getDictionary in DictionaryBrowserMain.tsx",
            error
          )
        );
    }
  }, [dictionaryId]);

  if (!dictionary || !dictionaryId) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }

  const cannotMoveLeft = () => {
    return currentIndex === 0;
  };

  const cannotMoveRight = () => {
    return currentIndex === dictionary.items.length - 1;
  };

  const moveLeft = () => {
    if (cannotMoveLeft()) return;
    setCurrentIndex(currentIndex - 1);
  };
  const moveRight = () => {
    if (cannotMoveRight()) return;
    setCurrentIndex(currentIndex + 1);
  };

  const buttonContainerClassName =
    "flex h-full align-items-center justify-content-center col-2 flex-column";

  return (
    <Card
      title={dictionary.label}
      pt={{
        root: { className: "h-full" },
        body: { className: "h-full" },
        title: { className: "w-full flex justify-content-center" },
        subTitle: { className: "w-full flex justify-content-center" },
        content: {
          className:
            "overflow-scroll flex flex-wrap align-items-around justify-content-around",
          style: { height: "calc(100% - 3.5rem)" },
        },
        footer: { className: "" },
      }}
    >
      <div
        className={`grid h-full w-6 grid-nogutter flex align-items-center justify-content-center ${classes.root}`}
      >
        <div className={buttonContainerClassName}>
          <Button
            icon="pi pi-arrow-left"
            onClick={moveLeft}
            disabled={cannotMoveLeft()}
          />
        </div>
        <div className="h-full col-8">
          <div className="w-full h-full flex flex-column">
            <FlipCard
              dictionaryItem={dictionary.items[currentIndex]}
              itemIndex={currentIndex}
            />
          </div>
        </div>
        <div className={buttonContainerClassName}>
          <Button
            icon="pi pi-arrow-right"
            onClick={moveRight}
            disabled={cannotMoveRight()}
          />
        </div>
      </div>
    </Card>
  );
}
