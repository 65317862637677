import React from "react";

import {
  useAccessToken,
  useNavigate,
  useLocation,
  Button,
  Card,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourse,
  addSection,
  // @ts-ignore
} from "@projectdiction/api-helper";
import InplaceEditableHeader from "./InplaceEditableHeader/InplaceEditableHeader";
import ScreenCreator from "../../Screen/ScreenCreator";

export default function SingleCourseCreator({
  parentViewUpdater,
  updateParentView,
}) {
  const [course, setCourse] = React.useState<any>();

  const accessToken = useAccessToken();

  const [viewUpdater, updateView] = React.useState(false);

  const { pathname } = useLocation();

  const courseId = pathname.includes("screen")
    ? undefined
    : pathname.split("/").pop();

  React.useEffect(() => {
    if (accessToken && courseId) {
      getCourse(accessToken, courseId)
        .then((response) => {
          setCourse(response.data.course);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, courseId, viewUpdater, parentViewUpdater]);

  const navigate = useNavigate();

  if (pathname.includes("screen")) {
    return (
      <ScreenCreator
        parentViewUpdater={parentViewUpdater}
        updateParentView={updateParentView}
      />
    );
  }

  if (!course) {
    return (
      <Card
        className="h-full w-full"
        title={<Skeleton />}
        subTitle={<Skeleton />}
      />
    );
  }

  return (
    <Card
      pt={{
        root: { className: "h-full w-full" },
        body: { className: "h-full w-full" },
        content: {
          className:
            "w-full overflow-scroll flex flex-wrap justify-content-around align-items-around",
          style: {
            height: course.parent
              ? "calc(100% - 3.5rem - 3.5rem - 0.5rem - 2.5rem)"
              : "calc(100% - 3.5rem - 0.5rem - 2.5rem)",
          },
        },
        footer: { className: "h-3.5rem w-full flex justify-content-center" },
      }}
      header={
        course.parent && (
          <InplaceEditableHeader
            title={course.title}
            setTitle
            description={course.description}
            setDescription
            courseId={courseId}
            updateParentView={() => {
              updateView(!viewUpdater);
            }}
          />
        )
      }
      footer={
        <>
          {course.sections.length === 0 && (
            <Button
              label="Add Screen"
              onClick={() => {
                navigate(pathname + "/screen/newScreen");
              }}
            />
          )}
          {course.screens.length === 0 && (
            <Button
              className="ml-2"
              label="Add Section"
              onClick={() => {
                addSection(accessToken, courseId)
                  .then(() => {
                    updateView(!viewUpdater);
                  })
                  .catch((error) => console.error(error));
              }}
            />
          )}
          {course.parent && (
            <Button
              className="ml-2"
              icon="pi pi-arrow-up"
              onClick={() => {
                navigate(pathname.replace(`/${courseId}`, ""));
              }}
            />
          )}
        </>
      }
    >
      {course.sections.map((section: any) => (
        <Card
          key={section._id}
          title={section.title}
          pt={{
            root: { className: "w-25rem h-10rem mb-4" },
            body: { className: "w-full h-full flex flex-column" },
            content: {
              className: "w-full pt-2 overflow-scroll h-full",
              style: { height: "calc(100% - 3.5rem - 3.5rem)" },
            },
            footer: { className: "w-full flex justify-content-end mt-auto" },
          }}
          footer={
            <Button
              label="View"
              onClick={() => {
                navigate(pathname + `/${section._id}`);
              }}
            />
          }
        ></Card>
      ))}
      {course.screens.map((screen: any) => (
        <Card
          key={screen._id}
          title={screen.title}
          pt={{
            root: { className: "w-25rem h-10rem mb-4" },
            body: { className: "w-full h-full flex flex-column" },
            content: {
              className: "w-full pt-2 overflow-scroll h-full",
              style: { height: "calc(100% - 3.5rem - 3.5rem)" },
            },
            footer: { className: "w-full flex justify-content-end mt-auto" },
          }}
          footer={
            <Button
              label="Edit"
              onClick={() => {
                navigate(`${pathname}/screen/${screen._id}`);
              }}
            />
          }
        >
          {screen.__t}
        </Card>
      ))}
    </Card>
  );
}
