import React from "react";

import {
  useAccessToken,
  useUserPreferences,
  NavigationGrid,
  Card,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getAllCourses,
  // @ts-ignore
} from "@projectdiction/api-helper";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function ViewerMain() {
  const { t } = useTranslation();

  const accessToken = useAccessToken();

  const userPreferences = useUserPreferences();

  const [courses, setCourses] = React.useState<any>();

  React.useEffect(() => {
    if (accessToken && userPreferences) {
      getAllCourses(
        accessToken,
        userPreferences.languages.studyLanguage,
        userPreferences.languages.knownLanguage
      )
        .then((response) => {
          console.log(response.data.courses);
          setCourses(response.data.courses);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, userPreferences]);

  if (!courses) {
    return (
      <Card className="w-full h-full">
        <Skeleton className="w-11 h-11" />
      </Card>
    );
  }

  return (
    <NavigationGrid
      title={t("courses.titles.courses")}
      data={courses.map((course) => {
        return {
          title: course.title,
          route: ROUTES.COURSES.VIEWER.COURSE(course._id),
          description: course.description,
          label: t("buttons.navigation.go"),
        };
      })}
    />
  );
}
