import React from "react";

import {
  useAccessToken,
  useParams,
  createUseStyles,
  useNavigate,
  Card,
  Skeleton,
  STYLES_CARD,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import ReadingItemSection from "./ReadingItemSection";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
  contentRoot: {
    height: "80vh",
  },
  textCard: {
    height: "97%",
    overflowY: "hidden",
    // "& .p-card-body .p-card-content": {
    //   height: "initial !important",
    // },
    "& .p-card-body": {
      height: "100%",
    },
    "& .p-card-content": {
      height: "80% !important",
      overflowY: "scroll",
    },
  },
  wordSpan: {
    cursor: "cell",
  },
});

export default function ReadingItem({ readingItem }) {
  const classes = useStyles();

  const rootClass = `${classes.textCard}`;
  const rootStyle = { height: "97%" };

  if (!readingItem) {
    return (
      <Card className={rootClass} style={rootStyle}>
        <Skeleton width="90%" height="90%" />
      </Card>
    );
  }

  return (
    <div className="flex align-items-center justify-content-center h-full w-full">
      <Card
        title={readingItem.title}
        subTitle={readingItem.subtitle}
        className={rootClass}
        style={rootStyle}
        pt={{
          title: { className: "w-full flex justify-content-center" },
          subTitle: { className: "w-full flex justify-content-center" },
        }}
      >
        <div className="flex flex-wrap h-full">
          {readingItem.text.map(
            (
              text: {
                headingLevel: 0;
                heading: string;
                content: Array<string>;
              },
              index: any
            ) => {
              return (
                <ReadingItemSection
                  key={index}
                  headingLevel={text.headingLevel}
                  heading={text.heading}
                  content={text.content}
                />
              );
            }
          )}
        </div>
      </Card>
    </div>
  );
}
