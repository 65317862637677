import React from "react";

import {
  Button,
  Card,
  Checkbox,
  useAccessToken,
  useLocation,
  ReactRouterLink,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourse,
  getScreen,
  updateScreen,
  getReadingItem,
  copyReadingItem,
  // @ts-ignore
} from "@projectdiction/api-helper";

import { SCREEN_TYPES } from "../../../../../SCREEN_TYPES";

import ReadingItemSelection from "./Select/ReadingItemSelection";
import ReadingItemForm from "./Form/ReadingItemForm";
import ReadingItem from "../../../../Viewer/Screens/Reading/ReadingScreen/ReadingItem";
import ReadingTranslationScreen from "../../../../Viewer/Screens/Reading/ReadingTranslationScreen/ReadingTranslationScreen";

export default function ReadingScreenCreatorMain({
  type,
  knownLanguage,
  studyLanguage,
  courseId,
  parentViewUpdater: viewUpdater,
  updateParentView: updateView,
}) {
  const accessToken = useAccessToken();

  const [createNew, setCreateNew] = React.useState(true);
  const [preview, setPreview] = React.useState(false);
  const [readingItem, _setReadingItem] = React.useState<any>();
  const [screen, setScreen] = React.useState<any>();

  const { pathname } = useLocation();
  const screenId = pathname.split("/").pop();

  React.useEffect(() => {
    getScreen(accessToken, screenId)
      .then((response) => {
        setScreen(response.data.screen);
        if (response.data.screen.readingItemId) {
          getReadingItem(accessToken, response.data.screen.readingItemId)
            .then((response) => {
              _setReadingItem(response.data.readingItem);
              setPreview(true);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken, screenId]);

  const updateScreenWithReadingItem = (readingItem) => {
    _setReadingItem(readingItem);
    updateScreen(accessToken, screenId, type, {
      update: {
        readingItemId: readingItem._id,
        title: readingItem.title,
      },
    })
      .then(() => {
        updateView(!viewUpdater);
        setPreview(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setReadingItem = (readingItem) => {
    if (createNew) {
      updateScreenWithReadingItem(readingItem);
      return;
    }
    getCourse(accessToken, courseId).then((response) => {
      copyReadingItem(
        accessToken,
        readingItem._id,
        response.data.course.dictionary
      ).then((response) => {
        const readingItem = response.data.readingItem;
        updateScreenWithReadingItem(readingItem);
      });
    });
  };

  return (
    <Card
      footer={
        <>
          <span>
            <Checkbox
              inputId="createNew"
              checked={createNew}
              onChange={(e) => setCreateNew(e.checked)}
            />
            <label htmlFor="createNew">Create New</label>
          </span>
          <span>
            <Checkbox
              inputId="preview"
              checked={preview}
              onChange={(e) => setPreview(e.checked)}
            />
            <label htmlFor="preview">Preview</label>
          </span>
          {readingItem && (
            <ReactRouterLink
              to={ROUTES.READING.READING_LIST.VIEW(readingItem._id)}
              target="_blank"
            >
              <Button label="View" />
            </ReactRouterLink>
          )}
        </>
      }
      pt={{
        root: { className: "h-full w-full" },
        body: { className: "flex flex-column h-full w-full" },
        content: {
          className: "w-full p-0",
          style: { overflow: "hidden", height: "calc(100% - 3rem)" },
        },
        footer: {
          className: "flex justify-content-start mt-auto w-full gap-4",
        },
      }}
    >
      {preview ? (
        type === SCREEN_TYPES.READING ? (
          <ReadingItem readingItem={readingItem} />
        ) : type === SCREEN_TYPES.READING_TRANSLATION ||
          type === SCREEN_TYPES.READING_POS ? (
          <ReadingTranslationScreen
            readingItemId={readingItem._id}
            studyLanguage={studyLanguage}
            knownLanguage={knownLanguage}
            type={type}
          />
        ) : null
      ) : createNew ? (
        <ReadingItemForm
          readingItem={readingItem}
          setReadingItem={setReadingItem}
        />
      ) : (
        <ReadingItemSelection
          readingItem={readingItem}
          setReadingItem={setReadingItem}
        />
      )}
    </Card>
  );
}
