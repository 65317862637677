import React from "react";

import {
  useNavigate,
  useParams,
  Card,
  Button,
  Splitter,
  SplitterPanel,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";
import ReadingScreen from "./ReadingScreen/ReadingScreen";
import VocabularyListScreen from "../Vocabulary/VocabularyListScreen";

export default function ReadingVocabularyScreen({
  readingItemId,
  dictionaryId,
}) {
  return (
    <Splitter className="h-full w-full">
      <SplitterPanel size={60}>
        <ReadingScreen readingItemId={readingItemId} />
      </SplitterPanel>
      <SplitterPanel size={30}>
        <VocabularyListScreen dictionaryId={dictionaryId} />
      </SplitterPanel>
    </Splitter>
  );
}
