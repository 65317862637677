import React from "react";

import {
  useAccessToken,
  useNavigate,
  useLocation,
  Button,
  Card,
  Skeleton,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourse,
  // @ts-ignore
} from "@projectdiction/api-helper";

import TOCMenuBreadCrumb from "./TOCMenuBreadCrumb";
import DuolingoStyleViewer from "./DuolingoStyleViewer/DuolingoStyleViewer";
import MainScreen from "../Screens/MainScreen";
import TOCMenu from "./TOCMenu";

export default function CourseViewerPage() {
  const { pathname } = useLocation();

  const containsScreen = pathname.includes("screen");

  const [course, setCourse] = React.useState<any>();
  const courseIds = pathname.replace("/courses/viewer/", "").split("/");

  const courseId = courseIds[0];

  const accessToken = useAccessToken();

  const [showMenu, setShowMenu] = React.useState(false);

  const isScreen = pathname.includes("screen");

  const navigate = useNavigate();

  const [viewUpdater, updateView] = React.useState(false);

  React.useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  React.useEffect(() => {
    if (accessToken) {
      getCourse(accessToken, courseId)
        .then((response) => {
          setCourse(response.data.course);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, courseId, viewUpdater]);

  return (
    <Card
      header={
        <div className="flex flex-column w-full">
          <div className="flex align-items-center justify-content-center w-full p-card-title">
            {course?.title}
          </div>
          <div className="flex align-items-center justify-content-center w-full p-card-subtitle">
            {course?.description}
          </div>
          {isScreen && (
            <div className="flex h-full w-full align-items-center gap-2">
              <div style={{ flex: "1 1 0" }}>
                <TOCMenuBreadCrumb />
              </div>
              <div style={{}}>
                <Button
                  icon="pi pi-bars"
                  text
                  onClick={() => setShowMenu(!showMenu)}
                />
              </div>
            </div>
          )}
        </div>
      }
      footer={
        !isScreen && (
          <>
            <Button
              label="Start Course"
              icon="pi pi-play-circle"
              className="p-button-primary h-full"
              onClick={() => navigate(course?.firstScreenPath)}
            />
            {course?.currentScreenPath && (
              <Button
                label="Continue"
                icon="pi pi-play"
                className="p-button-primary h-full"
                onClick={() => navigate(course?.currentScreenPath)}
              />
            )}
          </>
        )
      }
      pt={{
        root: { className: "card-root-with-header-footer" },
        body: { className: containsScreen && "p-0" },
        content: { className: containsScreen && "p-0" },
        footer: { className: "flex column-gap-2 justify-content-center" },
      }}
    >
      <div className="flex h-full w-full">
        <div
          className={`${
            showMenu ? "sm:w-9 sm:block hidden" : "w-12"
          } h-full overflow-auto`}
        >
          {containsScreen ? (
            <MainScreen
              viewUpdater={viewUpdater}
              updateView={() => updateView(!viewUpdater)}
            />
          ) : (
            <DuolingoStyleViewer />
          )}
        </div>
        {showMenu && (
          <div className="sm:w-3 w-full h-full overflow-auto">
            {/* <TOCMenu parentViewUpdater={viewUpdater} updateParentView={() => updateView(!viewUpdater)} /> */}
            <DuolingoStyleViewer />
          </div>
        )}
      </div>
    </Card>
  );
}
