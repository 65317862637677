import React from "react";

import {
  createUseStyles,
  // @ts-ignore
} from "@projectdiction/common-react";

const useStyles = createUseStyles({
  paragraph: {
    width: "40rem",
    cursor: "default",
    "user-select": "none",
  },
});
export default function ReadingItemSection({ headingLevel, heading, content }) {
  const classes = useStyles();

  const generateHeading = (headingLevel: number, heading: any) => {
    switch (headingLevel) {
      case 0:
        return null;
      case 2:
        return <h2>{heading}</h2>;
      case 3:
        return <h3>{heading}</h3>;
      case 4:
        return <h4>{heading}</h4>;
      case 5:
        return <h5>{heading}</h5>;
      case 6:
        return <h6>{heading}</h6>;
      default:
        return <h1>{heading}</h1>;
    }
  };

  const classCommon = `${classes.paragraph}`;

  return (
    <div className="flex flex-column w-full align-items-center">
      {generateHeading(headingLevel, <p className={classCommon}>{heading}</p>)}
      {content.map((paragraph: string, index: any) => {
        return (
          <p key={index} className={classCommon}>
            {paragraph}
          </p>
        );
      })}
    </div>
  );
}
