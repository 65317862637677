import React from "react";

import {
  useAccessToken,
  useParams,
  createUseStyles,
  useNavigate,
  Card,
  Skeleton,
  STYLES_CARD,
  ROUTES,
  ReadingItemHiglightPOSParagraph,
  ReadingItemParallelParagraph,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  getReadingItem,
  // @ts-ignore
} from "@projectdiction/api-helper";

import ReadingItemSection from "./ReadingItemSection";
import { SCREEN_TYPES } from "../../../../../SCREEN_TYPES";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
  contentRoot: {
    height: "80vh",
  },
  textCard: {
    height: "97%",
    overflowY: "hidden",
    // "& .p-card-body .p-card-content": {
    //   height: "initial !important",
    // },
    "& .p-card-body": {
      height: "100%",
    },
    "& .p-card-content": {
      height: "80% !important",
      overflowY: "scroll",
    },
  },
  wordSpan: {
    cursor: "cell",
  },
});

export default function ReadingTranslationScreen({
  readingItemId,
  studyLanguage,
  knownLanguage,
  type,
}) {
  const [readingItem, setReadingItem] = React.useState<any>();

  const token = useAccessToken();

  const classes = useStyles();

  const navigate = useNavigate();

  React.useEffect(() => {
    getReadingItem(token, readingItemId)
      .then((res) => {
        setReadingItem(res.data.readingItem);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [readingItemId, token]);

  const rootClass = `${classes.textCard}`;
  const rootStyle = { height: "97%" };

  if (!readingItem) {
    return (
      <Card className={rootClass} style={rootStyle}>
        <Skeleton width="90%" height="90%" />
      </Card>
    );
  }

  return (
    <div className="flex align-items-center justify-content-center h-full w-full">
      <Card
        title={
          type === SCREEN_TYPES.READING_TRANSLATION ? (
            <ReadingItemParallelParagraph
              text={readingItem.title}
              studyLanguage={studyLanguage}
              knownLanguage={knownLanguage}
            />
          ) : type === SCREEN_TYPES.READING_POS ? (
            // @ts-ignore
            <ReadingItemHiglightPOSParagraph text={readingItem.title} />
          ) : (
            readingItem.title
          )
        }
        className={rootClass}
        style={rootStyle}
        pt={{
          root: { className: "h-full w-full" },
          body: { className: "h-full w-full" },
          content: { className: "h-full w-full flex justify-content-center" },
          title: { className: "w-full flex justify-content-center" },
          subTitle: { className: "w-full flex justify-content-center" },
        }}
      >
        <div className="flex flex-wrap h-full w-30rem">
          {readingItem.text.map(
            (
              text: {
                headingLevel: 0;
                heading: string;
                content: Array<string>;
              },
              index: any
            ) => {
              return (
                <ReadingItemSection
                  key={index}
                  headingLevel={text.headingLevel}
                  heading={text.heading}
                  content={text.content}
                  studyLanguage={studyLanguage}
                  knownLanguage={knownLanguage}
                  type={type}
                />
              );
            }
          )}
        </div>
      </Card>
    </div>
  );
}
