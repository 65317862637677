import {
  NavigationGrid,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();

  return (
    <NavigationGrid
      title={t("courses.titles.courses")}
      data={[
        {
          title: t("courses.creator.titles.creator"),
          route: ROUTES.COURSES.CREATOR.HOME,
          description: t("courses.creator.description.creator"),
          label: t("buttons.navigation.go"),
        },
        {
          title: t("courses.viewer.titles.viewer"),
          route: ROUTES.COURSES.VIEWER.HOME,
          description: t("courses.viewer.description.viewer"),
          label: t("buttons.navigation.go"),
        },
      ]}
    />
  );
}
