import React from "react";

import {
  useAccessToken,
  useUserPreferences,
  NavigationGrid,
  Button,
  Card,
  Skeleton,
  ROUTES,
  ReadingCategories,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { getAllReadingItems } from "@projectdiction/api-helper";
// @ts-ignore
import { useTranslation } from "react-i18next";
import ReadingItemView from "./Views/ReadingItemView";
import ReadingItemListView from "./Views/ReadingItemListView";

export default function ReadingItemSelection({ readingItem, setReadingItem }) {
  const [readingItems, setReadingItems] = React.useState<any>();
  const [selectedReadingItem, setSelectedReadingItem] = React.useState<any>();
  const [readingItemSelected, setReadingItemSelected] =
    React.useState<boolean>(false);

  const { t } = useTranslation();

  const token = useAccessToken();
  const userPreferences = useUserPreferences();

  React.useEffect(() => {
    if (!userPreferences) return;
    getAllReadingItems(token, userPreferences.languages.studyLanguage)
      .then((response: any) => {
        setReadingItems(response.data.readingItems);
      })
      .catch((error: any) => console.log("error", error));
  }, [userPreferences]);

  if (!readingItems) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }
  return readingItemSelected ? (
    <ReadingItemView
      selectedReadingItem={selectedReadingItem}
      setReadingItemSelected={setReadingItemSelected}
      readingItem={readingItem}
      setReadingItem={setReadingItem}
    />
  ) : (
    <ReadingCategories
      onSingleItemClick={(readingItem) => {
        console.log(readingItem);
        setSelectedReadingItem(readingItem);
        setReadingItemSelected(true);
      }}
    />
  );
}
