import React from "react";

import {
  Button,
  Card,
  InplaceEditable,
  Skeleton,
  useNavigate,
  useLocation,
  useAccessToken,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getScreen,
  updateScreen,
  // @ts-ignore
} from "@projectdiction/api-helper";

import { SCREEN_TYPES } from "../../../SCREEN_TYPES";
import ReadingScreenCreatorMain from "./Screens/ReadingScreen/ReadingScreenCreatorMain";
import ReadingVocabularyScreenCreatorMain from "./Screens/ReadingVocabularyScreen/ReadingVocabularyScreenCreatorMain";
import ReadingPOSScreenCreatorMain from "./Screens/ReadingPOSScreen/ReadingPOSScreenCreatorMain";
import ReadingHighlightedWordsScreenCreatorMain from "./Screens/ReadingHighlightedWordsScreen /ReadingHighlightedWordsScreenCreatorMain";
import VocabularyListScreenCreatorMain from "./Screens/VocabularyListScreen /VocabularyListScreenCreatorMain";
import VocabularyDeckScreenCreatorMain from "./Screens/VocabularyDeckScreen/VocabularyDeckScreenCreatorMain";
import FillblankScreenCreatorMain from "./Screens/FillblankScreen/FillblankScreenCreatorMain";

export default function ScreenEditor({
  parentViewUpdater: viewUpdater,
  updateParentView: updateView,
  courseId,
}) {
  const [screen, setScreen] = React.useState<any>();

  const accessToken = useAccessToken();

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const screenId = pathname.split("/").pop();

  React.useEffect(() => {
    getScreen(accessToken, screenId)
      .then((response) => {
        setScreen(response.data.screen);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [screenId, viewUpdater]);

  if (!screen) {
    return (
      <Card
        pt={{
          root: { className: "h-full w-full" },
          body: { className: "h-full w-full" },
          title: { className: "w-full flex justify-content-center" },
          subTitle: { className: "w-full flex justify-content-center" },
          content: {
            className:
              "w-full flex justify-content-center flex-wrap overflow-scroll",
            style: { height: "calc(100% - 7rem)" },
          },
        }}
        title={<Skeleton className="w-10rem" />}
        subTitle={<Skeleton className="w-25rem" />}
      >
        {"12345".split("").map((_, i) => (
          <Skeleton key={i} className="w-23rem h-15rem m-4" />
        ))}
      </Card>
    );
  }

  const renderScreen = (screen) => {
    const type = screen.__t;
    switch (type) {
      case SCREEN_TYPES.READING:
        return (
          <ReadingScreenCreatorMain
            type={screen.__t}
            courseId={courseId}
            studyLanguage={screen.studyLanguage || "fr"}
            knownLanguage={screen.knownLanguage || "en"}
            parentViewUpdater={viewUpdater}
            updateParentView={updateView}
          />
        );
      case SCREEN_TYPES.READING_TRANSLATION:
        return (
          <ReadingScreenCreatorMain
            type={screen.__t}
            courseId={courseId}
            studyLanguage={screen.studyLanguage || "fr"}
            knownLanguage={screen.knownLanguage || "en"}
            parentViewUpdater={viewUpdater}
            updateParentView={updateView}
          />
        );
      case SCREEN_TYPES.READING_VOCABULARY:
        return <ReadingVocabularyScreenCreatorMain />;
      case SCREEN_TYPES.READING_POS:
        return (
          <ReadingScreenCreatorMain
            type={screen.__t}
            courseId={courseId}
            studyLanguage={screen.studyLanguage || "fr"}
            knownLanguage={screen.knownLanguage || "en"}
            parentViewUpdater={viewUpdater}
            updateParentView={updateView}
          />
        );
      case SCREEN_TYPES.READING_HIGHLIGHTED_WORDS:
        return <ReadingHighlightedWordsScreenCreatorMain />;
      case SCREEN_TYPES.VOCABULARY_LIST:
        return <VocabularyListScreenCreatorMain />;
      case SCREEN_TYPES.VOCABULARY_DECK:
        return <VocabularyDeckScreenCreatorMain />;
      case SCREEN_TYPES.FILL_BLANK:
        return <FillblankScreenCreatorMain />;
    }
  };

  return (
    <Card
      header={
        <>
          <InplaceEditable
            classes={{
              root: "flex h-2rem w-full p-card-title flex justify-content-center",
            }}
            text={screen.title}
            updateText={(title) => {
              updateScreen(accessToken, screenId, screen.__t, { title })
                .then(() => updateView(!viewUpdater))
                .catch((error) => console.error(error));
            }}
          />
          <InplaceEditable
            classes={{
              root:
                "flex h-2rem w-full p-card-subtitle flex justify-content-center" +
                (screen.description ? "" : " text-red-600"),
            }}
            text={screen.description || "Add Description!"}
            updateText={(description) => {
              updateScreen(accessToken, screenId, { description })
                .then(() => updateView(!viewUpdater))
                .catch((error) => console.error(error));
            }}
          />
        </>
      }
      footer={
        <Button
          icon="pi pi-arrow-up"
          onClick={() => navigate(pathname.replace(`/screen/${screenId}`, ""))}
        />
      }
      pt={{
        root: { className: "h-full w-full shadow-none p-0" },
        body: { className: "h-full w-full p-0" },
        title: { className: "w-full flex justify-content-center" },
        subTitle: { className: "w-full flex justify-content-center" },
        content: {
          className: "w-full flex justify-content-center p-0",
          style: { height: "calc(100% - 5.5rem)" },
        },
        footer: { className: "w-full flex justify-content-center" },
      }}
    >
      {renderScreen(screen)}
    </Card>
  );
}
