import React from "react";

import {
  Button,
  Card,
  useAccessToken,
  useLocation,
  useNavigate,
  ReactRouterLink,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

import {
  getCourse,
  // @ts-ignore
} from "@projectdiction/api-helper";

import SingleCourseCreator from "./SingleCourseCreator";
import TOCForm from "./TOCForm";
import TOCMenu from "./TOCMenu/TOCMenu";
import InplaceEditableHeader from "./InplaceEditableHeader/InplaceEditableHeader";
import TOCMenuBreadCrumb from "./TOCMenu/TOCMenuBreadCrumb";

export default function CourseCreatorPage() {
  const { pathname } = useLocation();

  const courseId = pathname.replace("/courses/creator/", "").split("/")[0];
  const isNewCourse = courseId === "newCourse";

  const accessToken = useAccessToken();

  const [viewUpdater, updateView] = React.useState(false);

  const [course, setCourse] = React.useState<any>();
  const [title, setTitle] = React.useState("Edit title here");
  const [description, setDescription] = React.useState("Edit description here");

  const [showMenu, setShowMenu] = React.useState(true);

  const { t } = useTranslation();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (accessToken && !isNewCourse) {
      getCourse(accessToken, courseId)
        .then((response) => {
          setCourse(response.data.course);
          setTitle(response.data.course.title);
          setDescription(response.data.course.description);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [accessToken, courseId, viewUpdater]);

  return (
    <Card
      header={
        <div className="flex flex-column w-full">
          <InplaceEditableHeader
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            courseId={courseId}
            updateParentView={() => updateView(!viewUpdater)}
          />
          <ReactRouterLink
            to={pathname.replace("creator", "viewer")}
            target="_blank"
            className="align-self-end"
          >
            <Button label="View Course" icon="pi pi-eye" />
          </ReactRouterLink>
          <div className="p-card-subtitle align-self-end">
            {t(`languages.${course?.studyLanguage}`) +
              " - " +
              t(`languages.${course?.knownLanguage}`)}
          </div>

          {!isNewCourse && (
            <div className="flex h-full w-full align-items-center">
              <div style={{ width: "max-content" }}>
                <TOCMenuBreadCrumb parentViewUpdater={viewUpdater} />
              </div>
              <Button
                icon="pi pi-bars"
                className="p-button-text p-button-sm ml-auto"
                onClick={() => setShowMenu(!showMenu)}
              />
            </div>
          )}
        </div>
      }
      pt={{
        root: { className: "h-full w-full" },
        title: { className: "w-full pl-2 flex justify-content-start" },
        body: {
          className: "w-full p-0",
          style: { height: "calc(100% - 11rem)" },
        },
        content: { className: "h-full w-full p-0" },
      }}
    >
      <div className="flex w-full h-full">
        <div className={`${showMenu ? "w-8" : "w-full"} h-full overflow-auto`}>
          {isNewCourse || (
            <SingleCourseCreator
              parentViewUpdater={viewUpdater}
              updateParentView={() => updateView(!viewUpdater)}
            />
          )}
        </div>
        <div
          className={`${
            showMenu || isNewCourse ? "w-4" : "w-0"
          } overflow-auto flex align-items-center mt-2`}
        >
          {isNewCourse ? (
            <TOCForm title={title} description={description} />
          ) : (
            showMenu && (
              <div className="h-full w-full">
                <TOCMenu
                  parentViewUpdater={viewUpdater}
                  updateParentView={() => updateView(!viewUpdater)}
                />
              </div>
            )
          )}
        </div>
      </div>
    </Card>
  );
}
