import React from "react";
import {
  getDictionary,
  // @ts-ignore
} from "@projectdiction/api-helper";

import {
  createUseStyles,
  confirmDialog,
  useNavigate,
  Card,
  ConfirmDialog,
  Button,
  InputText,
  Divider,
  Dialog,
  Toast,
  Skeleton,
  ROUTES,
  useAccessToken,
  useParams,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

export default function VocabularyListScreen({ dictionaryId }) {
  const [dictionary, setDictionary] = React.useState<any>();

  const { t } = useTranslation();

  const token = useAccessToken();

  React.useEffect(() => {
    if (dictionaryId) {
      getDictionary(token, { dictionaryId })
        .then((response: any) => {
          setDictionary(response.data.dictionary);
        })
        .catch((error: any) =>
          console.log(
            "error in getDictionary in DictionaryBrowserMain.tsx",
            error
          )
        );
    }
  }, [dictionaryId]);

  if (!dictionary || !dictionaryId) {
    return (
      <div className="flex flex-column w-9 h-full">
        <Skeleton width="100%" height="2rem" />
        <Skeleton width="100%" height="15rem" />
      </div>
    );
  }

  return (
    <Card
      title={dictionary.label}
      pt={{
        root: { className: "h-full" },
        body: { className: "h-full" },
        title: { className: "w-full flex justify-content-center" },
        subTitle: { className: "w-full flex justify-content-center" },
        content: {
          className:
            "overflow-scroll flex flex-wrap align-items-around justify-content-around",
          style: { height: "calc(100% - 3.5rem)" },
        },
        footer: { className: "" },
      }}
    >
      {dictionary.items.map((d, index) => {
        const { word, meaning } = d;
        return (
          <Card
            title={word}
            subTitle={meaning}
            key={index}
            pt={{
              root: { className: "h-9rem w-16rem" },
              body: { className: "" },
              title: { className: "" },
              subTitle: { className: "" },
              content: { className: "" },
              footer: { className: "" },
            }}
          />
        );
      })}
    </Card>
  );
}
