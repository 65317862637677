import React from "react";

import {
  useAccessToken,
  useNavigate,
  useLocation,
  BreadCrumb,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourseMenu,
  getScreen,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function TOCMenuBreadCrumb() {
  let { pathname } = useLocation();

  const containsScreen = pathname.includes("screen");

  const courseId = containsScreen
    ? pathname.split("/").slice(-3)[0]
    : pathname.split("/").pop();

  const token = useAccessToken();

  const [courseMenu, setCourseMenu] = React.useState<any>();
  const [menuItems, setMenuItems] = React.useState<any>();

  const navigate = useNavigate();

  const [screenId, setScreenId] = React.useState<string>();

  React.useEffect(() => {
    if (!token) return;

    getCourseMenu(token, courseId)
      .then((response) => {
        setCourseMenu(response.data.course);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token, courseId]);

  React.useEffect(() => {
    if (!courseMenu) return;

    setScreenId(null);

    let courseIds = pathname.replace("/courses/viewer/", "").split("/");

    let screenId;

    if (pathname.includes("screen")) {
      courseIds = courseIds.slice(0, courseIds.length - 2);
      screenId = pathname.split("/").pop();
      setScreenId(screenId);
    }

    if (courseIds.length === 1) {
      return;
    }

    let course = courseMenu;

    let path = `/courses/viewer/${course._id}`;

    let menuItems: any = [];

    for (let i = 1; i < courseIds.length; i++) {
      course = course.sections.find((section) => section._id === courseIds[i]);

      // path += `/${course._id}`;

      menuItems.push({
        key: course._id,
        label: course.title,
        data: path,
        command: (e: any) => navigate(e.item.data),
      });
    }

    setMenuItems(menuItems);
  }, [courseMenu, courseId, pathname]);

  React.useEffect(() => {
    if (!menuItems) return;

    if (!screenId) return;

    let screenMenuItem = menuItems.find(
      (menuItem) => menuItem.key === screenId
    );
    if (screenMenuItem) return;

    let path = menuItems[menuItems.length - 1].data;

    path += `/screen/${screenId}`;

    screenMenuItem = {
      key: screenId,
      data: path,
    };

    getScreen(token, screenId)
      .then((response) => {
        const screen = response.data.screen;

        screenMenuItem.template = (
          <div className="underline" style={{ cursor: "default" }}>
            {" "}
            {screen.title}
          </div>
        );

        setMenuItems(menuItems.concat(screenMenuItem));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [screenId, pathname, courseId]);

  if (!courseMenu) {
    return null;
  }

  if (!menuItems) {
    return null;
  }

  return (
    <BreadCrumb
      home={{
        label: courseMenu.title,
        command: () => {
          setMenuItems(null);
          navigate(`/courses/viewer/${courseMenu._id}`);
        },
      }}
      model={menuItems || []}
      separatorIcon={menuItems ? null : "pi"}
      //lets animate this thing!
      pt={{
        root: { className: "" },
        menu: { className: "" },
        menuitem: { className: "animate__animated animate__jello" },
      }}
    />
  );
}
