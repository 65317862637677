import React from "react";

import {
  createUseStyles,
  useDrag,
  usePreview,
  Button,
  Card,
  STYLES_CARD,
  STYLES_FLEX_CENTER_CENTER,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  root: {},
  front: {},
  back: {},
  card: {
    "& .p-card-body .p-card-content": {
      ...STYLES_FLEX_CENTER_CENTER,
      fontSize: "2rem",
      height: "100% !important",
    },
    ...STYLES_CARD,
  },
});

const FlipCard = ({ dictionaryItem, itemIndex }) => {
  const { word, meaning } = dictionaryItem;

  const classes = useStyles();

  const [cardAnimation, setCardAnimation] = React.useState("");

  const [showBack, setShowBack] = React.useState<boolean>(false);

  const animate = () => {
    setCardAnimation("");
    setTimeout(() => setCardAnimation("animate__flipInX"), 1);
  };

  React.useEffect(() => {
    setShowBack(false);
  }, [itemIndex]);

  const { t } = useTranslation();

  const cardClassName = `h-full w-full ${classes.card} flex justify-content-center align-items-center`;

  return (
    <>
      <div
        className={`w-full h-full animate__animated ${cardAnimation}`}
        onClick={() => {
          animate();
          setShowBack(!showBack);
        }}
      >
        {showBack ? (
          <Card
            title={t("vocabulary.titles.meaning")}
            className={`${cardClassName} ${classes.back} bg-orange-500`}
          >
            {meaning}
          </Card>
        ) : (
          <Card
            title={t("vocabulary.titles.word")}
            className={`${cardClassName} ${classes.front} bg-cyan-500`}
          >
            {word}
          </Card>
        )}
      </div>
    </>
  );
};

export default FlipCard;
