export const parseTableOfContents = (tableOfContents: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(tableOfContents, "text/html");
  const ul = doc.querySelector("ul");
  let children: any = ul.children;

  children = Array.from(children);

  const maxDepth = Math.max(
    ...children
      .map((child: any) => child.className)
      .map((text) => Number(text.replace("ql-indent-", "")))
  );

  const data = {};

  for (let d = 0; d <= maxDepth; d++) {
    for (let i = 0; i < children.length; i++) {
      const depth = Number(children[i].className.replace("ql-indent-", ""));

      if (depth === d) {
        if (data[d] === undefined) {
          data[d] = [i];
        } else {
          data[d].push(i);
        }
      }
    }
  }

  const rec = (d) => {
    const currentDepthIndices = data[d];
    const nextDepthIndices = data[d + 1];

    if (d === maxDepth) {
      return currentDepthIndices.map((currentDepthIndex: number) => {
        return { title: children[currentDepthIndex].textContent, sections: [] };
      });
    }

    return currentDepthIndices.map((currentDepthIndex: number, cdi: number) => {
      const mapFoo = (nextDepthIndex: number) => {
        const ndi = nextDepthIndices.indexOf(nextDepthIndex);
        return rec(d + 1)[ndi];
      };
      return {
        title: children[currentDepthIndex].textContent,
        sections:
          cdi === currentDepthIndices.length - 1
            ? // [] :
              nextDepthIndices
                .filter((index: number) => index > currentDepthIndex)
                .map(mapFoo)
            : nextDepthIndices
                .filter(
                  (index: number) =>
                    index > currentDepthIndex &&
                    index < currentDepthIndices[cdi + 1]
                )
                .map(mapFoo),
      };
    });
  };

  return rec(0);
};

export const reverseParseTableOfContents = (data: any) => {
  let string = "<ul>";

  function rec(data, level = 0) {
    data.forEach((course) => {
      let listString = `<li>${course.title}</li>`;

      if (level > 0) {
        listString = `<li class="ql-indent-${level}">${course.title}</li>`;
      }

      string += listString;

      rec(course.sections, level + 1);
    });
  }

  rec(data);

  return string + "</ul>";
};
