import React from "react";

import {
  useAccessToken,
  createUseStyles,
  STYLES_CARD,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getReadingItem,
  // @ts-ignore
} from "@projectdiction/api-helper";
import ReadingItem from "./ReadingItem";

const useStyles = createUseStyles({
  root: {
    ...STYLES_CARD,
  },
  contentRoot: {
    height: "80vh",
  },
  textCard: {
    height: "97%",
    overflowY: "hidden",
    // "& .p-card-body .p-card-content": {
    //   height: "initial !important",
    // },
    "& .p-card-body": {
      height: "100%",
    },
    "& .p-card-content": {
      height: "80% !important",
      overflowY: "scroll",
    },
  },
  wordSpan: {
    cursor: "cell",
  },
});

export default function ReadingScreen({ readingItemId }) {
  const [readingItem, setReadingItem] = React.useState<any>();

  const token = useAccessToken();

  React.useEffect(() => {
    getReadingItem(token, readingItemId)
      .then((res) => {
        setReadingItem(res.data.readingItem);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [readingItemId, token]);

  return <ReadingItem readingItem={readingItem} />;
}
