import React from "react";

import {
  useAccessToken,
  useParams,
  createUseStyles,
  useNavigate,
  Card,
  Button,
  Skeleton,
  STYLES_CARD,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

// @ts-ignore
import { useTranslation } from "react-i18next";
import ReadingItemSection from "./ReadingItemSection";

export default function ReadingItemView({
  selectedReadingItem,
  setReadingItemSelected,
  readingItem,
  setReadingItem,
}) {
  if (!selectedReadingItem) {
    return (
      <Card>
        <Skeleton width="90%" height="90%" />
      </Card>
    );
  }

  return (
    <Card
      title={selectedReadingItem.title}
      subTitle={selectedReadingItem.subtitle}
      footer={
        <>
          <Button
            label="Back"
            onClick={() => {
              setReadingItemSelected(false);
            }}
          />
          <Button className="ml-3" label="Edit" onClick={() => {}} />
          <Button
            className="ml-3"
            label="Confirm Selection"
            onClick={() => {
              setReadingItem(selectedReadingItem);
            }}
          />
        </>
      }
      pt={{
        root: { className: "h-full w-full" },
        title: { className: "flex justify-content-center w-full" },
        content: { className: "w-full", style: { overflow: "auto" } },
        body: { className: "flex flex-column h-full w-full" },
        footer: { className: "flex justify-content-end mt-auto w-full" },
      }}
    >
      {selectedReadingItem.text.map(
        (
          text: {
            headingLevel: 0;
            heading: string;
            content: Array<string>;
          },
          index: any
        ) => {
          return (
            <ReadingItemSection
              key={index}
              headingLevel={text.headingLevel}
              heading={text.heading}
              content={text.content}
            />
          );
        }
      )}
    </Card>
  );
}
