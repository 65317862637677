import React from "react";

import {
  useAccessToken,
  InplaceEditable,

  // @ts-ignore
} from "@projectdiction/common-react";

import {
  updateCourse,
  // @ts-ignore
} from "@projectdiction/api-helper";

export default function InplaceEditableHeader({
  courseId,
  title,
  setTitle,
  description,
  setDescription,
  updateParentView,
}) {
  const accessToken = useAccessToken();
  return (
    <>
      <InplaceEditable
        key="title"
        text={title}
        updateText={(title) => {
          if (courseId === "newCourse") {
            setTitle(title);
            return;
          }
          updateCourse(accessToken, courseId, {
            title,
          }).then(() => {
            updateParentView();
          });
        }}
        classes={{
          root: `flex h-2rem p-card-title w-full flex justify-content-center m-0`,
        }}
      />
      <InplaceEditable
        key="description"
        text={description}
        updateText={(description) => {
          if (courseId === "newCourse") {
            setDescription(description);
            return;
          }
          updateCourse(accessToken, courseId, {
            description,
          }).then(() => {
            updateParentView();
          });
        }}
        classes={{
          root: `flex h-2rem p-card-subTitle w-full flex justify-content-center m-0`,
        }}
      />
    </>
  );
}
