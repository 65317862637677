import React from "react";

import {
  Button,
  Card,
  Editor,
  Inplace,
  InplaceDisplay,
  InplaceContent,
  InputText,
  PanelMenu,
  useAccessToken,
  useUserPreferences,
  useNavigate,
  ROUTES,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  addCourseTOC,
  // @ts-ignore
} from "@projectdiction/api-helper";
import { parseTableOfContents } from "./functions";

export default function TOCForm({ title, description }) {
  const accessToken = useAccessToken();

  const userPreferences = useUserPreferences();

  const navigate = useNavigate();

  const [tableOfContents, setTableOfContents] =
    React.useState<string>(`<ul></ul>`);
  return (
    <Editor
      value={tableOfContents}
      formats={["list", "indent"]}
      headerTemplate={
        <div className="flex">
          <button className="ql-list" value="bullet"></button>

          <Button
            label="I"
            onClick={() => {
              setTableOfContents(
                tableOfContents.replace("</ul>", "") +
                  "<li>Introduction</li></ul>"
              );
            }}
          />
          <Button
            label="C"
            onClick={() => {
              setTableOfContents(
                tableOfContents.replace("</ul>", "") + "<li>Chapter</li></ul>"
              );
            }}
          />
          <Button
            label="S"
            onClick={() => {
              setTableOfContents(
                tableOfContents.replace("</ul>", "") +
                  "<li class='ql-indent-1'>Section</li></ul>"
              );
            }}
          />
          <Button
            label="SS"
            onClick={() => {
              setTableOfContents(
                tableOfContents.replace("</ul>", "") +
                  "<li class='ql-indent-2'>SubSection</li></ul>"
              );
            }}
          />
          <Button
            className="w-3rem ml-auto"
            label="Save"
            onClick={() => {
              addCourseTOC(
                accessToken,
                title,
                description,
                JSON.stringify(parseTableOfContents(tableOfContents)),
                userPreferences.languages.knownLanguage,
                userPreferences.languages.studyLanguage
              )
                .then((res) => {
                  navigate(`/courses/creator/${res.data._id}`);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        </div>
      }
      pt={{
        root: { className: "w-full", style: { height: "calc(100% - 1.5rem)" } },
      }}
      onTextChange={(e) => setTableOfContents(e.htmlValue)}
    />
  );
}
