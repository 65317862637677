import React from "react";

import {
  Button,
  Card,
  Editor,
  InplaceEditable,
  PanelMenu,
  Menubar,
  useAccessToken,
  useUserPreferences,
  useNavigate,
  useLocation,
  // @ts-ignore
} from "@projectdiction/common-react";

import {
  getCourseMenu,
  // @ts-ignore
} from "@projectdiction/api-helper";
import TOCMenuItem from "./TOCMenuItem";

export default function TOCMenu({ parentViewUpdater, updateParentView }) {
  let { pathname } = useLocation();

  const containsScreen = pathname.includes("screen");

  const courseId = containsScreen
    ? pathname.split("/").slice(-3)[0]
    : pathname.split("/").pop();

  const [menuItems, setMenuItems] = React.useState<any>();
  const [expandedKeys, setExpandedKeys] = React.useState<any>({});

  const token = useAccessToken();

  const [courseMenu, setCourseMenu] = React.useState<any>();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!token) return;

    getCourseMenu(token, courseId)
      .then((response) => {
        setCourseMenu(response.data.course);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token, courseId, parentViewUpdater]);

  function getCourseIds() {
    let courseIds: string[];
    if (containsScreen) {
      const screenId = pathname.split("/").pop();
      courseIds = pathname
        .replace(`/courses/creator`, "")
        .replace(`/screen/${screenId}`, "")
        .split("/");
    } else {
      courseIds = pathname.replace(`/courses/creator`, "").split("/");
    }
    return courseIds;
  }

  React.useEffect(() => {
    let courseIds = getCourseIds();

    courseIds.forEach((key) => {
      expandedKeys[key] = true;
    });

    setExpandedKeys(expandedKeys);
  }, [pathname]);

  React.useEffect(() => {
    if (!courseMenu) return;

    function createMenuItems(data, path) {
      const courseIds = getCourseIds();

      return data.map((chapter, i) => {
        const hasSections = chapter.sections.length > 0;
        const hasScreens = chapter.screens.length > 0;
        return {
          key: chapter._id,
          label: chapter.title,
          items: hasSections
            ? createMenuItems(chapter.sections, `${path}/${chapter._id}`)
            : chapter.screens.map((screen) => {
                return {
                  key: screen._id,
                  label: screen.title,
                  data: `${path}/${chapter._id}/screen/${screen._id}`,
                  command: (e) => {
                    navigate(e.item.data);
                  },
                  template: (
                    <TOCMenuItem
                      key={screen._id}
                      item={screen}
                      updateParentView={updateParentView}
                      path={`${path}/${chapter._id}/screen/${screen._id}`}
                      type="screen"
                    />
                  ),
                };
              }),
          data: `${path}/${chapter._id}`,
          command: (e) => {
            navigate(e.item.data);
          },
          template: (
            <TOCMenuItem
              key={chapter._id}
              item={chapter}
              updateParentView={updateParentView}
              path={`${path}/${chapter._id}`}
              type="course"
            />
          ),
          expanded: courseIds.includes(chapter._id),
        };
      });
    }

    setMenuItems(
      createMenuItems(courseMenu.sections, `/courses/creator/${courseMenu._id}`)
    );
  }, [courseMenu]);

  return (
    <PanelMenu
      pt={{}}
      submenuIcon="pi"
      // expandedKeys={expandedKeys}
      // onExpandedKeysChange={setExpandedKeys}
      model={menuItems || []}
      multiple
    />
  );
}
