import React from "react";

import {
  OverlayPanel,
  useNavigate,
  useLocation,
  // @ts-ignore
} from "@projectdiction/common-react";

function getColorBasedOnDepth(depth) {
  const colors = [
    "bg-green-200",
    "bg-blue-200",
    "bg-yellow-200",
    "bg-pink-200",
    "bg-purple-200",
    "bg-indigo-200",
  ];

  return colors[depth % colors.length];
}

function getCharacterBasedOnDepth(depth) {
  const characters = ["C", "SA", "SB", "SC", "SD", "SE", "SF", "SG", "SH"];

  return characters[depth % characters.length];
}
export default function DuolingoStyleBubble({
  item,
  options,
  type,
  currentScreenId,
}) {
  const isScreen = type === "screen";
  const isCourse = type === "course";

  const { hasScreens, hasSections, depth } = item.data;

  const navigate = useNavigate();

  const overlayPanelRef = React.useRef<OverlayPanel>(null);

  if (isScreen) {
    return null;
  }

  if (!isCourse) {
    return <div>{item.label}?????</div>;
  }

  if (!hasSections && !hasScreens) {
    return null;
  }

  if (hasScreens) {
    const containsCurrentScreen =
      currentScreenId &&
      item.items.some((subItem) => {
        return subItem.data.screenId === currentScreenId;
      });
    return (
      <>
        <div
          className="flex align-items-center justify-content-center flex-column cursor-pointer select-none"
          onClick={(e) => {
            overlayPanelRef.current?.toggle(e);
          }}
        >
          <div
            className={`${getColorBasedOnDepth(
              depth
            )} text-info text-3xl border-circle h-5rem w-5rem flex align-items-center justify-content-center animate__animated animate__fadeInDown`}
          >
            {getCharacterBasedOnDepth(depth)}
          </div>
          {item.label}
          {containsCurrentScreen && "✅"}
        </div>
        <OverlayPanel
          ref={overlayPanelRef}
          closeIcon="pi pi-times"
          showCloseIcon
          key={item.key}
        >
          <div className="flex overflow-auto" style={{ maxWidth: "80vw" }}>
            {item.items.map((item) => {
              return (
                <div
                  key={item.key}
                  className="flex flex-column align-items-center justify-content-center select-none m-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(item.data.path);
                  }}
                >
                  <div
                    className={`${getColorBasedOnDepth(
                      item.data.depth
                    )} text-info text-3xl border-circle h-5rem w-5rem flex align-items-center justify-content-center animate__animated animate__fadeInDown`}
                  >
                    {/* {item.data.depth + 1} */}
                    {getCharacterBasedOnDepth(item.data.depth)}
                  </div>
                  {item.label}
                  {item.key === currentScreenId && "✅"}
                </div>
              );
            })}
          </div>
        </OverlayPanel>
      </>
    );
  }

  if (hasSections) {
    let shouldDisplay = false;

    item.items.forEach((subItem) => {
      if (subItem.data.hasSections || subItem.data.hasScreens) {
        shouldDisplay = true;
      }
    });

    if (!shouldDisplay) {
      return null;
    }
  }

  return (
    <div
      className="flex align-items-center justify-content-center flex-column select-none"
      style={{ cursor: "default" }}
    >
      <div
        className={`${getColorBasedOnDepth(
          depth
        )} text-info text-3xl border-circle h-5rem w-5rem flex align-items-center justify-content-center animate__animated animate__fadeInDown`}
      >
        {getCharacterBasedOnDepth(depth)}
      </div>
      {item.label}
    </div>
  );
}
